import React, { useEffect, useState } from "react";
import auth0 from "auth0-js";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

import AuthSidebar from "./AuthSidebar";
import AuthRegister from "./forms/AuthRegister";
import AuthLogin from "./forms/AuthLogin";
import AuthPasswordReset from "./forms/AuthPasswordReset";
import AuthMFALogin from "./forms/AuthMFALogin";
import getLoginOptions from "shared/helpers/getLoginOptions";
import Loading from "shared/components/Loading";
import isDevelopment from "shared/helpers/isDevelopment";
import { useDispatch } from "react-redux";
import { requireUserMfa } from "./requireUserMfa";
import LanguageSelector from "../components/LanguageSelector";
import { fetchMFADataUnauth } from "shared/redux/actions/fetchMFADataAction";
import { clearSiteData } from "shared/helpers/clearSiteData";

import "./style.scss";

const Authentication = ({ defaultRoute }) => {

    const [passwordReset, setPasswordReset] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true);
    const [mfa, setMfa] = useState(false);
    const [checkedAuth, setCheckedAuth] = useState(false);
    const dispatch = useDispatch();
    const sevenDaysInSeconds = 7 * 24 * 60 * 60;
    const cookieOptions = { path: "/", domain: !isDevelopment() ? ".okcomply.com" : undefined, maxAge: sevenDaysInSeconds };

    const [isRegistration, setRegistration] = useState(window.location.pathname.endsWith("register"));

    const setEmailValue = (email) => {
        localStorage.setItem("email", email);
        setEmail(email);
    };


    const redirectToDesiredLocation = () => {
        const redirect = localStorage.getItem("redirect");
        localStorage.removeItem("redirect");

        window.location = redirect?.length > 0 ? `/${redirect}` : defaultRoute;
    };

    useEffect(() => {
        const checkAuth = async () => {

            const cookies = new Cookies();
            const now = new Date().getTime();
            const accessToken = cookies.get("access_token");
            const expiresAt = cookies.get("expires_at");

            if (accessToken && expiresAt) {

                if (now > JSON.parse(expiresAt)) {
                    await clearSiteData();
                    window.location.href = "/login";
                    return;
                }

                window.location.href = "/tasks";
                return;
            }

            if (window.location.hash?.length > 0) {
                const webAuth = new auth0.WebAuth(getLoginOptions());
                webAuth.parseHash({ hash: window.location.hash }, async (err, authResult) => {
                    if (err) {
                        return console.log(err);
                    }
                    const expiresAt = JSON.stringify((authResult.expiresIn * 1000 * 7) + new Date().getTime());
                    cookies.set("access_token", authResult.accessToken, cookieOptions);
                    cookies.set("id_token", authResult.idToken, cookieOptions);
                    cookies.set("expires_at", expiresAt, cookieOptions);

                    const mfaData = await fetchMFADataUnauth(localStorage.getItem("email"));
                    if (mfaData && mfaData.enabled && mfaData.verified) {
                        await requireUserMfa(localStorage.getItem("email"));
                        localStorage.setItem("mfa", true);
                        cookies.set("mfa_required", true, cookieOptions);
                        window.location.href = `${window.location.origin}/login`;
                    } else {
                        localStorage.removeItem("mfa");
                        cookies.remove("mfa_required", cookieOptions);
                        redirectToDesiredLocation();
                    }
                });
            } else {
                setLoading(false);
            }
        };

        if (!checkedAuth) {
            setCheckedAuth(true);
            checkAuth();
        }

        let root = document.getElementsByTagName("html")[0];
        root.className = "";

        return () => {
            let root = document.getElementsByTagName("html")[0];
            root.className = "has-navbar-fixed-top";
        };
    }, [checkedAuth]);

    const renderPageContent = () => {
        if (passwordReset) {
            return (
                <AuthPasswordReset
                    setEmail={setEmailValue}
                    email={email}
                    setPasswordReset={setPasswordReset}
                />
            );
        }
        if (isRegistration) {
            return (
                <AuthRegister
                    email={email}
                    setEmail={setEmailValue}
                    setRegistration={setRegistration}
                />
            );
        } else {
            return (
                <AuthLogin
                    setEmail={setEmailValue}
                    email={email}
                    setPasswordReset={setPasswordReset}
                    setRegistration={setRegistration}
                />
            );
        }
    };

    return (
        <div className="container-no-nav">
            <div className="onboarding-landing">
                <div className="language-selector-container">
                    <LanguageSelector />
                </div>
                <AuthSidebar />
                <div className="form-landing">
                    {!mfa && (!loading ? renderPageContent() : <Loading />)}

                    {mfa && !passwordReset && !isRegistration && <AuthMFALogin onSuccesfullMFALogin={() => {
                        const cookies = new Cookies();
                        cookies.remove("mfa_required", cookieOptions);
                        redirectToDesiredLocation();
                        // localStorage.removeItem("mfa");
                    }} />}
                </div>
            </div>
        </div>
    );
};

Authentication.propTypes = {
    defaultRoute: PropTypes.string.isRequired,
};

export default Authentication;
